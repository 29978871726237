<template>
  <div class="home">
    <slideshow
      :slideshowList="slideshowList"
      @gotodetail="handleGo"
    ></slideshow>
    <div class="content-flow">
      <div class="content-census">
        <div class="content-module content-module1">
          <img src="../../static/home/icon-1.png" alt="" />
          <p>13685人</p>
          <span>最大承载量</span>
        </div>
        <div class="content-module content-module2">
          <img src="../../static/home/icon-2.png" alt="" />
          <p>6842人</p>
          <span>瞬时承载量</span>
        </div>
        <div class="content-module content-module3">
          <img src="../../static/home/icon-3.png" alt="" />
          <p>{{ people }}人</p>
          <span>实时游客量</span>
        </div>
        <div class="content-module content-module4">
          <img
            :src="require(`../../static/home/shushi-${comfort.icon}.png`)"
            alt=""
          />
          <p>{{ comfort.describe }}</p>
          <span>游览舒适度</span>
        </div>
      </div>
    </div>
    <!-- <div class="notice">
      <el-button style="background:#ffde3d" round @click="handleMore">了解更多</el-button>
    </div> -->
    <div class="home-content">
      <div
        class="content-grid"
        v-for="(item, index) in imgList"
        :key="index"
        @click="handleJump(item.jumpImage, index)"
      >
        <img :src="item.imagePath" alt="" />
        <div class="content-text">
          <p>{{ item.imageName }}</p>
        </div>
      </div>
      <!-- <div class="content-grid1">
        <img src="../../static/home/img6.png" alt />
        <div class="content-text">
          <p>走进条子泥。</p>
        </div>
      </div>
      <div class="content-grid2">
        <img src="../../static/home/img7.png" alt />
        <div class="content-text">
          <p>玩转条子泥。</p>
        </div>
      </div>
      <div class="content-grid3">
        <img src="../../static/home/img8.png" alt />
        <div class="content-text">
          <p>虚拟条子泥。</p>
        </div>
      </div>
      <div class="content-grid4">
        <img src="../../static/home/img9.png" alt />
        <div class="content-text">
          <p>条子泥攻略。</p>
        </div>
      </div>
      <div class="content-grid5">
        <img src="../../static/home/img10.png" alt />
        <div class="content-text">
          <p>在线预订。</p>
        </div>
      </div>
      <div class="content-grid6">
        <img src="../../static/home/img11.png" alt />
        <div class="content-text">
          <p>勺子商城。</p>
        </div>
      </div>
      <div class="content-grid7">
        <img src="../../static/home/img12.png" alt />
        <div class="content-text">
          <p>小程序二维码</p>
        </div>
      </div> -->
    </div>
    <!-- <img class="tznsd" src="../../static/home/tznsd.png" alt=""> -->
    <!-- <img class="sign" src="../../static/home/sign.png" alt=""> -->
  </div>
</template>

<script>
// import HelloWorld from "@/components/HelloWorld.vue";
import { slideshow, tzn_klssAPI, tzn_tpzsAPI } from "@/api/test/test";
export default {
  name: "Home",
  components: {
    // HelloWorld
  },
  data () {
    return {
      slideshowList: [], //轮播图
      people: "", //实时流量
      comfort: {
        //舒适度
        describe: "",
        icon: 1,
      },
      imgList: [], //图片展示
    };
  },
  created () {
    //实时客流
    tzn_klssAPI().then((res) => {
      this.people = res[0].ssnum;
      console.log(res, "1111");
      this.handleGetComfortLevel();
    });
  },
  mounted () {
    slideshow().then((res) => {
      this.slideshowList = res;
      console.log(this.slideshowList);
    });
    //content-images
    tzn_tpzsAPI().then((res) => {
      console.log(res, "imges");
      this.imgList = res.data;
    });
  },
  methods: {
    //轮播图跳转
    handleGo (jumpPath) {
      // console.log(jumpPath,'ccc');
      window.location.href = jumpPath;
    },
    //实时舒适度
    handleGetComfortLevel () {
      this.$nextTick(() => {
        let num = (this.people / 15000) * 100;
        switch (true) {
          case num >= 80:
            this.comfort.describe = "拥挤";
            this.comfort.icon = 5;
            break;
          case num >= 60:
            this.comfort.describe = "较拥挤";
            this.comfort.icon = 4;
            break;
          case num >= 40:
            this.comfort.describe = "一般";
            this.comfort.icon = 3;
            break;
          case num >= 20:
            this.comfort.describe = "较为舒适";
            this.comfort.icon = 2;
            break;
          case num >= 0:
            this.comfort.describe = "舒适";
            this.comfort.icon = 1;
            break;
        }
      });
    },
    //了解更多
    handleMore () {
      window.location.href =
        "https://mp.weixin.qq.com/s/Vp17ZTArcQ5luJIuycgK1A";
    },
    //数据格式化
    decodeHTML (str) {
      var s = "";
      if (!str || str.length == 0) return "";
      s = str.replace(/&amp;/g, "&");
      s = s.replace(/&lt;/g, "<");
      s = s.replace(/&gt;/g, ">");
      s = s.replace(/&nbsp;/g, " ");
      s = s.replace(/&#39;/g, "'");
      s = s.replace(/&quot;/g, '"');
      return s;
    },
    //跳转
    handleJump (url, index) {
      console.log(url, "----------");
      this.$router.push({
        path: url,
      });
      this.$store.commit("active", index + 1);
    },
  },
};
</script>
<style scoped>
.home {
  height: 100%;
  position: relative;
}
/deep/ .el-carousel__arrow {
  width: 106px;
  height: 106px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
/deep/ .el-carousel__arrow--left {
  background-image: url("../../static/home/carousel-left.png");
}
/deep/ .el-carousel__arrow--right {
  background-image: url("../../static/home/carousel-right.png");
}

.home-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  /* top: -1px; */
  z-index: 1;
}
.content-grid {
  display: flex;
  position: relative;
  cursor: pointer;
}
.content-grid > img {
  opacity: 1;
}
.content-grid > img:hover {
  opacity: 0.7;
}

.content-grid:nth-of-type(1) {
  width: 70%;
}
.content-grid:nth-of-type(1) > img {
  width: 100%;
  height: 460px;
}
.content-grid:nth-of-type(2) {
  width: 30%;
}
.content-grid:nth-of-type(2) > img {
  width: 100%;
  height: 460px;
}
.content-grid:nth-of-type(3) {
  width: 39%;
}
.content-grid:nth-of-type(3) > img {
  width: 100%;
  height: 360px;
}
.content-grid:nth-of-type(4) {
  width: 39%;
}
.content-grid:nth-of-type(4) > img {
  width: 100%;
  height: 360px;
}
.content-grid:nth-of-type(5) {
  width: 22%;
}
.content-grid:nth-of-type(5) > img {
  width: 100%;
  height: 360px;
}
.content-grid:nth-of-type(6) {
  width: 72%;
}
.content-grid:nth-of-type(6) > img {
  width: 100%;
  height: 460px;
}
.content-grid:nth-of-type(7) {
  width: 28%;
}
.content-grid:nth-of-type(7) > img {
  width: 100%;
  height: 460px;
}
.content-grid > .content-text {
  width: 192px;
  height: 285px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 5%;
  /* right: 0; */
  margin: auto;
  background: url("../../static/home/img13.png") no-repeat 100% 100%;
  background-size: cover;
}
.content-text > p {
  width: 30px;
  font-size: 30px;
  font-weight: bold;
  line-height: 36px;
  color: #317981;
  position: absolute;
  left: 58px;
  top: 27px;
  margin: 0 !important;
}

/deep/.el-image {
  width: 100%;
}
/deep/.el-image :hover {
  cursor: pointer;
}
.notice {
  width: 100%;
  height: 486px;
  background-image: url("../../static/home/notice.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.notice > button {
  position: absolute;
  margin-left: 310px;
  margin-top: 300px;
  width: 220px;
  height: 70px;
  border-radius: 10px;
  font-size: 36px;
}
/deep/ .el-button:hover {
  color: #fff;
}
.content-flow {
  width: 100%;
  height: 430px;
  background-image: url("../../static/home/statistics.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
}
.content-flow > .content-census {
  width: 55%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  margin-top: 60px;
}
.content-census > .content-module1 {
  background-image: url("../../static/home/bg-1.png");
}
.content-census > .content-module2 {
  background-image: url("../../static/home/bg-2.png");
}
.content-census > .content-module3 {
  background-image: url("../../static/home/bg-3.png");
}
.content-census > .content-module4 {
  background-image: url("../../static/home/bg-4.png");
}
.content-census > .content-module {
  width: 210px;
  height: 210px;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  padding: 30px 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-size: 100% 100%;
}
.content-module > img {
  width: 36px;
  height: 36px;
}
.content-module > p {
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
}
.content-module > span {
  font-size: 12px;
  color: #ffffff;
}
.tznsd {
  position: absolute;
  top: 38px;
  left: 43px;
  z-index: 99;
}
.sign {
  position: absolute;
  right: 30px;
  top: 330px;
  z-index: 99;
  animation: moveX 16s linear infinite alternate,
    moveY 18s linear infinite alternate;
}
@keyframes moveX {
  from {
    left: 0;
  }

  to {
    left: calc(100% - 15em);
  }
}
@keyframes moveY {
  from {
    top: 0;
  }

  to {
    top: calc(100% - 15em);
  }
}
</style>
